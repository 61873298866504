<script>
import { useStore } from 'vuex'
import { onMounted } from 'vue'

import ButtonAnswersTemplate from "@/components/shared/base-components/ButtonAnswersTemplate"

const stepName = 'HaveYouUsedTobacco'

const ANSWERS = [
  'Yes',
  'No'
]

export default {
  name: stepName,

  emits: ['next-step'],

  components: {
    ButtonAnswersTemplate
  },

  setup(_, {emit}) {
    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const store = useStore()

    const setAnswer = (userInput) => {
      store.commit('SET_FIELD', {field: 'used_tobacco_last_12_months', value: userInput.toLowerCase()})
      emit('next-step', stepName)
    }

    return {
      ANSWERS,
      setAnswer
    }
  }
}
</script>

<template>
  <div class="question-container">
    <button-answers-template
      :button-action="setAnswer"
      :answers="ANSWERS"
    >
      <slot></slot>
    </button-answers-template>
  </div>
</template>